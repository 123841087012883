import { Controller } from "@hotwired/stimulus"
import { useClickOutside, useTransition } from 'stimulus-use';

export default class extends Controller {
  static targets = ["normal", "inverted"]
  static values = { revealed: { type: Boolean, default: false } }

  initialize() {
    // this.leave() is asynchronous but it reads form the transition class to figure out how long
    // to wait. i think that's insufficient when calling hide twice back to back. i think the first
    // event winds up waiting, but the second event calls leave again and this time it resolves
    // instantly. idk if that's useTransition not properly reading the duration of the transition,
    // or losing it on subseuqent calls, or requestAnimationFrame returning more quickly (useTransition
    // has a `await nextAnimationFrame`, too).
    //
    // but, we can hack around this buy keeping a stack of invocations and ensuring we bail early
    // if the first one is still waiting on leave to finish.
    this.__unfinished_hide_calls__ = []
  }

  connect() {
    useClickOutside(this, { eventPrefix: false })
    useTransition(this, {
      // useTransition can only handle a single element transitioning, so run through our
      // normalTargets and find the one configured to transition. (i guess we could support
      // transitioning an invertedTarget, but then we'd have to track that to know if this.show
      // should enter or leave the transition.)
      element: [...this.normalTargets].find((e) => {
        return [...e.attributes].some((a) => a.name.startsWith("data-transition"))
      }),
      transitioned: this.revealedValue,
      hiddenClass: false
    })
  }

  show() {
    this.normalTargets.forEach((e) => e.style.display = "revert")
    this.invertedTargets.forEach((e) => e.style.display = "none")

    this.revealedValue = true
    this.enter()
  }

  async hide() {
    this.__unfinished_hide_calls__.push(Math.random())

    if (this.__unfinished_hide_calls__.length > 1) {
      this.__unfinished_hide_calls__.pop()
      return
    }

    await this.leave()

    this.normalTargets.forEach((e) => e.style.display = "none")
    this.invertedTargets.forEach((e) => e.style.display = "revert")

    this.revealedValue = false
    this.__unfinished_hide_calls__.pop()
  }

  toggle() {
    this.revealedValue ? this.hide() : this.show()
  }

  escape(event) {
    if (event.keyCode === 27) { // escape
      this.hide()
    }
  }
}
